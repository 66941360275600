import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum ModalType {
    Warning = "warning",
    Success = "success",
    Error = "error",
    Info = "info",
}

export interface ModalState {
    show: boolean;
    title: string;
    body: string;
    type?: ModalType;
    buttonLabel?: string;
    onClose?: () => void;
}

interface UiState {
    generalModal: ModalState;
}

const initialState: UiState = {
    generalModal: {show: false, body: "", title: "", type: ModalType.Warning, onClose: () => {}}
}

const uiReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        closeModal(state) {
            state.generalModal.show = false;
        },
        openModal(state, action: PayloadAction<ModalState>) {
            state.generalModal = action.payload;
            state.generalModal.show = true;
        },
        toggleModal(state, action) {
            state.generalModal.show = !state.generalModal.show;
        }
    }
});

export const {toggleModal, openModal, closeModal} = uiReducer.actions;

export default uiReducer.reducer;