
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { createContext, useEffect, ReactNode } from "react";
import {SignOutUser, userStateListener} from "../../Services/Firebase/hooks";
import {ROUTE} from "../../Services/Router/routes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Services/Redux/rootState";
import {setAuthenticated} from "../../Services/Redux/features/userReducer";


interface Props {
    children?: ReactNode
}

export const AuthContext = createContext({
    currentUser: {} as User | null,
    setCurrentUser: (_user: User) => {},
    signOut: () => {}
});

export const AuthProvider = ({ children }: Props) => {
    const dispatch = useDispatch()
    const currentUser = useSelector((state: RootState) => state.user.user)
    const navigate = useNavigate()

    useEffect(() => {
        return userStateListener((user) => {
            if (user) {
                dispatch(setAuthenticated(user))
            }
        });
    }, [dispatch]);

    const signOut = () => {
        SignOutUser()
        dispatch(setAuthenticated(null))
        navigate(ROUTE.SIGN_IN)
    }

    const setCurrentUser = (user: User| null) => {
        dispatch(setAuthenticated(user))
    }

    const value = {
        currentUser,
        setCurrentUser,
        signOut
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
  