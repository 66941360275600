import NotFoundComponent from "../../content/NotFoundComponent";
import React from "react";

interface Props {

}

const NotFoundPage: React.FC<Props> = () => {

    return (
        <div>
            <NotFoundComponent />
        </div>
    )
}

export default NotFoundPage