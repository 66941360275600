import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export enum ModalType {
    Warning = "warning",
    Success = "success",
    Error = "error",
    Info = "info",
}

interface ModalState {
    show: boolean;
    title: string;
    body: string;
    type: ModalType;
    buttonLabel: string;
    onClose: () => void;
}

const initialState: ModalState = {
    show: false,
    title: "",
    body: "",
    type: ModalType.Info,
    buttonLabel: "Sluiten",
    onClose: () => {}
}

export interface ModalSettings {
    title: string;
    body: string;
    type?: ModalType;
    buttonLabel?: string;
    onClose?: () => void;
}

const modalReducer = createSlice({
    name: "modal",
    initialState,
    reducers: {
        closeModal(state) {
            state.show = false;
        },
        openModal(state, action: PayloadAction<ModalSettings>) {
            state.title = action.payload.title
            state.body = action.payload.body
            if (action.payload.type === undefined) {
                state.type = ModalType.Info
            }
            if (action.payload.buttonLabel === undefined) {
                state.buttonLabel = "Sluiten"
            }
            if (action.payload.onClose === undefined) {
                state.onClose = () => {}
            }

            state.show = true;
        },
        toggleModal(state, action) {
            state.show = !state.show;
        }
    }
});

export const {toggleModal, openModal, closeModal} = modalReducer.actions;

export default modalReducer.reducer;