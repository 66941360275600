import React, {useEffect} from 'react';
import {SignOutUser} from "../../../Services/Firebase/hooks";
import {useNavigate} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes";
import {useDispatch} from "react-redux";
import {setAuthenticated} from "../../../Services/Redux/features/userReducer";

const LogoutPage: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        SignOutUser()
            .then(() => {
                dispatch(setAuthenticated(null))
                navigate(ROUTE.SIGN_IN)
            })
            .catch((e) => {
                navigate(ROUTE.SIGN_IN)
            })
    });

    return (
        <></>
    )
};

export default LogoutPage


