import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import DefaultPage from "./Components/pages/DefaultPage";
import {ROUTES} from "./Services/Router/routes";
import NotFoundPage from "./Components/pages/NotFoundPage";
import AuthenticatedPage from "./Components/pages/AuthenticatedPage";
import {useSelector} from "react-redux";
import {RootState} from "./Services/Redux/rootState";
import ModalComponent from "./Components/content/ModalComponent";
import PrivateRoutesPage from "./Components/pages/PrivateRoutesPage";

function App() {
    const showModal = useSelector((state: RootState) => state.modal.show)
    return (
        <div className="App">
            {showModal && <ModalComponent />}
            <Routes>
                {ROUTES.default.map((rt) =>
                    (<Route path={rt.route} element={<DefaultPage>{rt.children}</DefaultPage>} key={rt.route}/>)
                )})
                <Route element={<PrivateRoutesPage />}>
                    {ROUTES.authenticated.map((rt) =>
                        (<Route path={rt.route} element={<AuthenticatedPage>{rt.children}</AuthenticatedPage>} key={rt.route}/>)
                    )})
                </Route>
                <Route path='*' element={<NotFoundPage />}/>
            </Routes>
        </div>
    );
}

export default App;
