import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes";

interface Props {

}

const Root: React.FC<Props> = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate(ROUTE.SIGN_IN)
    })
    return (
        <div className="App">
        </div>
    );
};

export default Root


