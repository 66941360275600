import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {signInUser} from "../../../Services/Firebase/hooks";
import {setAuthenticated, setFirstName, setLastName} from "../../../Services/Redux/features/userReducer";
import {Field, Form, Formik} from "formik";
import React from "react";
import {ROUTE} from "../../../Services/Router/routes";
import useAppUser from "../../../Services/Hooks/User";

interface Props {

}

interface FormValues {
    email: string;
    password: string;
}

const initialValues: FormValues = {
    email: '',
    password: '',
}

const LoginFormComponent: React.FC<Props> = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {GetMetaData, RecordLastLogin} = useAppUser()

    const handleSubmit = async (values: FormValues) => {
        try {
            const userCredential = await signInUser(values.email, values.password)
            if (userCredential) {
                dispatch(setAuthenticated(userCredential.user))
                RecordLastLogin(userCredential.user.uid)
                await GetMetaData(userCredential.user.uid)
                    .then((userData) => {
                        if (userData.firstName === undefined || userData.lastName === undefined || userData.firstName === '' || userData.lastName === '') {
                            navigate(ROUTE.ONBOARDING)
                        } else {
                            dispatch(setFirstName(userData.firstName))
                            dispatch(setLastName(userData.lastName))
                            navigate(ROUTE.DASHBOARD)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        // navigate(ROUTE.ONBOARDING)
                    });
            }
        }
        catch (e: any) {
            console.log(e)

        }
    }
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-24 w-auto"
                        src="/ehbo-featured-192_x_110.png"
                        alt="EHBO Castricum"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                        Login bij Hulp aan Zee
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400">
                                    E-mailadres
                                </label>
                                <div className="mt-2">
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400">
                                        Wachtwoord
                                    </label>
                                    <div className="text-sm">
                                        <NavLink to={ROUTE.PASSWORD_FORGOTTEN} className="font-semibold text-blue-600 hover:text-blue-500">
                                            Wachtwoord vergeten?
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 dark:bg-blue/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
                                >
                                    Inloggen
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}
export default LoginFormComponent;