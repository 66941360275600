import React, {useEffect} from 'react';
import useAppUser from "../../../Services/Hooks/User";

interface Props {
}

const AdminsListenComponent: React.FC<Props> = () => {

    const {ListenForAdmins} = useAppUser()

    useEffect(() => {
        ListenForAdmins()
            .catch(console.log)
        // eslint-disable-next-line
    }, []);

    return (
        <></>
    );
};

export default AdminsListenComponent


