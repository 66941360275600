import { Navigate, Outlet } from 'react-router-dom'
import {useSelector} from "react-redux";
import {RootState} from "../../../Services/Redux/rootState";
import {ROUTE} from "../../../Services/Router/routes";
import React from "react";

interface Props {

}

const PrivateRoutes: React.FC<Props> = () => {
    const isAuthenticated = useSelector((state: RootState) => state.user.authenticated)
    return (
        isAuthenticated ? <Outlet/> : <Navigate to={ROUTE.SIGN_IN} />
    )
}

export default PrivateRoutes