import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from 'firebase/auth';

interface UsrState {
    authenticated: boolean;
    user: User | null;
    firstName: string | null;
    lastName: string | null;
    admins: string[];
    users: string[];
}

const initialState: UsrState = {
    authenticated: false,
    user: null,
    firstName: null,
    lastName: null,
    admins: [],
    users: []
}

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAuthenticated(state, action: PayloadAction<User | null>) {
            if (action.payload !== null) {
                state.authenticated = true;
                state.user = action.payload;
            } else {
                state.authenticated = false;
                state.user = null;
                state.firstName = null;
                state.lastName = null;
            }
        },
        setFirstName(state, action: PayloadAction<string | null>) {
            state.firstName = action.payload;
        },
        setLastName(state, action: PayloadAction<string | null>) {
            state.lastName = action.payload;
        },
        setAdminList(state, action: PayloadAction<string[]>) {
            state.admins = action.payload;
        },
        setUsers(state, action: PayloadAction<string[]>) {
            state.users = action.payload;
        }
    }
});

export const {setAuthenticated, setFirstName, setLastName, setAdminList} = userReducer.actions;

export default userReducer.reducer;