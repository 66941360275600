import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../../../Context/AuthContext";
import {useNavigate} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes";
import {useDispatch} from "react-redux";
import {setAuthenticated} from "../../../Services/Redux/features/userReducer";
import {userStateListener} from "../../../Services/Firebase/hooks";
import useAppUser from "../../../Services/Hooks/User";

interface Props {
}

const AuthCheckComponent: React.FC<Props> = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { currentUser} = useContext(AuthContext)
    const {RecordLastLogin} = useAppUser()

    useEffect(() => {
        if (!currentUser) {
            dispatch(setAuthenticated(null))
            navigate(ROUTE.SIGN_IN)
        }

        userStateListener((user) => {
            if (!user) {
                dispatch(setAuthenticated(null))
                navigate(ROUTE.SIGN_IN)
            }
        });
        setInterval(sendAlive, 60000)
        // eslint-disable-next-line
    }, [currentUser, dispatch, navigate]);

    const sendAlive = () => {
        if (currentUser) {
            RecordLastLogin(currentUser.uid)
        }
    }

    return (
        <></>
    );
};

export default AuthCheckComponent


