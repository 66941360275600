import React, {useEffect, useState} from "react";

interface Props {

}

const notFoundGifs = [
    "https://media.giphy.com/media/C21GGDOpKT6Z4VuXyn/giphy.gif",
    "https://media.giphy.com/media/9J7tdYltWyXIY/giphy.gif",
    "https://media.giphy.com/media/rqoATGnsKBWqaM53Rl/giphy.gif",
    "https://media.giphy.com/media/lqFHf5fYMSuKcSOJph/giphy.gif",
    "https://media.giphy.com/media/lqFHf5fYMSuKcSOJph/giphy.gif"
]

const NotFoundComponent: React.FC<Props> = () => {
    const [gif, setGif] = useState(notFoundGifs[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setGif(notFoundGifs[Math.floor(Math.random() * notFoundGifs.length)])
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex items-center justify-center flex-col h-screen">
            <h1 className="text-4xl font-bold text-center">Pagina is niet gevonden.</h1><br />
            <img src={gif} alt="Pagina is niet gevonden." className='rounded-2xl'/>
        </div>

    )
}

export default NotFoundComponent;