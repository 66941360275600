import React from 'react';

interface Props {
}

const DashboardPage: React.FC<Props> = () => {

    return (
        <div>
            axsxass
        </div>
    );
};

export default DashboardPage


