import {ReactNode} from "react";

interface Props {
    children: ReactNode;
}

const DefaultPage = ({children} : Props) => {
    return (
        <div>
            {children}
        </div>
    )
}

export default DefaultPage