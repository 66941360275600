import {styleButtonSubmit, styleFormAstrix, styleFormError, styleFormInput, styleFormLabel, styleHeading3, styleTextParagraph} from "../../../utils/styles";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes";
import useAppUser from "../../../Services/Hooks/User";
import {setFirstName, setLastName} from "../../../Services/Redux/features/userReducer";

interface Props {

}

interface FormValues {
    firstName: string;
    lastName: string;
    telephone: string;
}

const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    telephone: '',
}

const transformTelephone = (value: string) => {
    value = value.replaceAll(' ', '');
    value = value.replaceAll('-', '');
    value = value.replaceAll('+31', '0');

    return value
}

const OnboardComponent: React.FC<Props> = () => {

    const {SetMetaData, TransformTelephone} = useAppUser()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().min(1).max(30).required('Voornaam is verplicht'),
        lastName: Yup.string().min(1).max(30).required('Achternaam is verplicht'),
        telephone: Yup.string().transform(TransformTelephone).matches(/^0/, "Telefoon nummer moet met een 0 beginnen").matches(/^([0-9]*)$/, "Een telefoon nummer bestaat enkel cijfers.").min(10, 'Telefoon nummer bestaat uit 10 cijfers').max(10, 'Telefoon nummer bestaat uit 10 cijfers').optional(),
    });

    const handleSubmit = async (values: FormValues) => {
        values.telephone = transformTelephone(values.telephone)
        SetMetaData(values)
            .then(() => {
                dispatch(setFirstName(values.firstName))
                dispatch(setLastName(values.lastName))
                navigate(ROUTE.DASHBOARD)
            })
            .catch(console.log)
    }

    return (
        <>
            <div className="mx-auto max-w-2xl px-10">
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ errors, touched }) => (
                        <Form>
                            <div className="space-y-12">
                                <div className="mt-20">
                                    <img
                                        className="h-24 w-auto float-right"
                                        src="/ehbo-featured-512_x_295.png"
                                        alt="EHBO Vereniging Castricum"
                                    />
                                    <h1 className="text-3xl dark:text-white">Welkom!</h1>
                                    <p className={styleTextParagraph}>Dit is de eerste keer dat je inlogt bij hulp aan zee! Fijn dat je voor ons vrijwilligers werk wilt gaan doen. We hebben een klein beetje informatie van je nodig zodat we verder kunnen.</p>
                                </div>

                                <div className="border-b border-gray-900/10 pb-12 dark:border-gray-300">
                                    <h2 className={styleHeading3}>Persoonlijke informatie</h2>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="first-name" className={styleFormLabel}>
                                                Voornaam<span className={styleFormAstrix}>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    placeholder="Voornaam"
                                                    autoComplete="given-name"
                                                    className={styleFormInput}
                                                />
                                                {errors.firstName && touched.firstName ? (
                                                    <div className={styleFormError}>{errors.firstName}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="last-name" className={styleFormLabel}>
                                                Achternaam<span className={styleFormAstrix}>*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    placeholder="Achternaam"
                                                    autoComplete="family-name"
                                                    className={styleFormInput}
                                                />
                                                {errors.lastName && touched.lastName ? (
                                                    <div className={styleFormError}>{errors.lastName}</div>
                                                ) : null}

                                            </div>
                                        </div>

                                        <div className="sm:col-span-4">
                                            <label htmlFor="telephone" className={styleFormLabel}>
                                                Telefoon nummer
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    id="telephone"
                                                    name="telephone"
                                                    type="tel"
                                                    autoComplete="tel"
                                                    placeholder="0612345678"
                                                    className={styleFormInput}
                                                />
                                                {errors.telephone && touched.telephone ? (
                                                    <div className={styleFormError}>{errors.telephone}</div>
                                                ) : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button
                                    type="submit"
                                    className={styleButtonSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default OnboardComponent;