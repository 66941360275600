import React from "react";
import PasswordResetFormComponent from "../../content/PasswordResetFormComponent";

interface Props {

}

const PasswordResetPage: React.FC<Props> = () => {
    return (
        <>
            <PasswordResetFormComponent />
        </>
    )
}

export default PasswordResetPage