import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";
import {resetUserPassword} from "../../../Services/Firebase/hooks";
import {ModalType, openModal} from "../../../Services/Redux/features/modalReducer";
import {ROUTE} from "../../../Services/Router/routes";

interface Prop {

}

interface FormValues {
    email: string;
    password: string;
}

const initialValues: FormValues = {
    email: '',
    password: '',
}

const PasswordResetFormComponent: React.FC<Prop> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCloseModal = () => {
        navigate(ROUTE.SIGN_IN)
    }

    const handleSubmit = async (values: FormValues) => {
        resetUserPassword(values.email)
            .then(() => {
                dispatch(openModal({title: "Nieuw wachtwoord", body: "Er is een e-mail gestuurd met een nieuw wachtwoord. Bekijk de e-mail en reset uw wachtwoord.", onClose: onCloseModal}))
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/invalid-email':
                        dispatch(openModal({title: "E-mail foutief", body: "Er is een fout met uw email adres. Probeer opnieuw.", type: ModalType.Warning}))
                        break;
                    case 'auth/user-not-found':
                        dispatch(openModal({title: "Gebruiker onbekend", body: "Dit email is bij ons onbekend. Neem contact op met de vereniging om u toe te voegen.", type: ModalType.Error}))
                        break;
                    default:
                        dispatch(openModal({title: "Technische error!", body: "Error: " + error.code, type: ModalType.Error}))
                }
            })
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-24 w-auto"
                        src="/ehbo-featured-192_x_110.png"
                        alt="EHBO Castricum"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                        Login bij Hulp aan Zee
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400">
                                    E-mailadres
                                </label>
                                <div className="mt-2">
                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 dark:bg-blue/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
                                >
                                    Inloggen
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}
export default PasswordResetFormComponent