import {UserCircleIcon} from "@heroicons/react/20/solid";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../Services/Redux/rootState";
import {NavLink} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes";

interface Props {

}

const ToolbarProfileComponent: React.FC<Props> = () => {
        const firstName = useSelector((state: RootState) => state.user.firstName)
        const lastName = useSelector((state: RootState) => state.user.lastName)
        return (
            <NavLink
                to={ROUTE.SIGN_OUT}
                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue-700">
                <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                <span className="sr-only">Jouw profiel</span>
                <span aria-hidden="true">{firstName ?? ""} {lastName ?? ""}</span>
            </NavLink>

        );
}

export default ToolbarProfileComponent