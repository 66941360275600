import React, {useEffect} from 'react';
import useAppUser from "../../../Services/Hooks/User";

interface Props {
}

const OnboardCheckComponent: React.FC<Props> = () => {

    const {ListenForMetaData} = useAppUser()

    useEffect(() => {
        ListenForMetaData()
            .catch(console.log)
    }, [ListenForMetaData]);

    return (
        <></>
    );
};

export default OnboardCheckComponent


