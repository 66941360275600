import {get, onValue, ref, remove, set, update} from "firebase/database";
import {useDispatch, useSelector} from "react-redux";
import {DUser, DUserMetadata} from "../../Firebase/dalModels/users";
import {RootState} from "../../Redux/rootState";
import {firebaseDatabase} from "../../Firebase";
import {DB_PATHS, DB_SEPARATOR} from "../../Firebase/dal/dbRoutes";
import {useNavigate} from "react-router-dom";
import {ROUTE} from "../../Router/routes";
import {setAdminList, setFirstName, setLastName} from "../../Redux/features/userReducer";

type userReturnType = {
    GetMetaData: (uid?: string) => Promise<DUserMetadata>
    GetUser: (uid: string) => Promise<DUser>
    SetMetaData: (metaData: DUserMetadata) => Promise<void>
    UpdateMetaData: (metaData: DUserMetadata) => Promise<void>
    TransformTelephone: (value: string) => string
    Uid: () => string
    ListenForMetaData: () => Promise<void>
    IsAdmin: (uid?: string) => boolean
    ListenForAdmins: () => Promise<void>
    RecordLastLogin: (uid?: string) => void
    SaveUser: (uid: string, user: DUser) => void
}


const useAppUser = (): userReturnType => {

    const user = useSelector((state: RootState) => state.user.user)
    const admins = useSelector((state: RootState) => state.user.admins)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Get the MetaData of the current user
    const GetMetaData = async (uid?: string): Promise<DUserMetadata> =>  {
        if (uid === undefined) {
            uid = Uid()
        }

        const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + uid + DB_PATHS.USERS_METADATA)
        return new Promise<DUserMetadata>((resolve, reject) => {
            get(userRef).then((snapshot) => {
                if (!snapshot.exists()) {
                    console.log("No data available");
                    reject()
                }
                resolve(snapshot.val())
            }).catch((error) => {
                console.error(error);
                reject()
            });
        })
    }

    // Set the MetaData of the current user
    const SetMetaData = (metaData: DUserMetadata): Promise<void> => {
        if (user !== null) {
            const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + Uid() + DB_PATHS.USERS_METADATA)
            return set(userRef, metaData)
        }
        return Promise.reject()
    }

    // Update the MetaData of the current user
    const UpdateMetaData = (metaData: DUserMetadata): Promise<void> => {
        if (user !== null) {
            const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + Uid() + DB_PATHS.USERS_METADATA)
            return update(userRef, metaData)
        }
        return Promise.reject()
    }

    // Clean up phone number
    const TransformTelephone = (value: string) => {
        value = value.replaceAll(' ', '');
        value = value.replaceAll('-', '');
        value = value.replaceAll('+31', '0');

        return value
    }

    // Get the UID of the current user
    const Uid = (): string => {
        return user?.uid ?? ""
    }

    const ListenForMetaData = async () => {
        const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + Uid() + DB_PATHS.USERS_METADATA)
        onValue(userRef, (snapshot) => {
            const data: DUserMetadata = snapshot.val()
            if (data.firstName === undefined || data.lastName === undefined || data.firstName === '' || data.lastName === '') {
                navigate(ROUTE.ONBOARDING)
            }else {
                dispatch(setFirstName(data.firstName))
                dispatch(setLastName(data.lastName))
            }
        })
    }

    const ListenForAdmins = async () => {
        onValue(ref(firebaseDatabase, DB_PATHS.ADMINS), (snapshot) => {
            const data = snapshot.val()
            if (data !== null) {
                const admins = Object.keys(data)
                dispatch(setAdminList(admins))
            }
        });
    }

    const IsAdmin = (uid?: string): boolean => {
        if (uid === undefined) {
            uid = Uid()
        }
        return admins.includes(uid)
    }

    const RecordLastLogin = (uid?: string) => {
        if (uid === undefined) {
            uid = Uid()
        }
        const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + uid + DB_PATHS.USERS_METADATA + DB_PATHS.USERS_LAST_LOGIN)
        set(userRef, Date.now())
    }

    const GetUser = (uid: string): Promise<DUser> => {
        const userRef = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + uid)
        return new Promise<DUser>((resolve, reject) => {
            get(userRef).then((snapshot) => {
                if (!snapshot.exists()) {
                    console.log("No data available");
                    reject()
                }
                resolve(snapshot.val())
            }).catch((error) => {
                console.error(error);
                reject()
            });
        })
    }

    const SaveUser = (uid: string, user: DUser) => {
        if (user.metadata) {
            const userRefMetaData = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + uid + DB_PATHS.USERS_METADATA)
            update(userRefMetaData, user.metadata)
                .catch(console.log)
        }
        if (user.active !== undefined) {
            const userRefActive = ref(firebaseDatabase, DB_PATHS.USERS + DB_SEPARATOR + uid + DB_PATHS.USERS_ACTIVE)
            set(userRefActive, user.active)
                .catch(console.log)
        }
        if (user.admin !== undefined) {
            const userAdminActive = ref(firebaseDatabase, DB_PATHS.ADMINS + DB_SEPARATOR + uid)
            if (user.admin) {
                set(userAdminActive, user.admin)
                    .catch(console.log)
            } else {
                remove(userAdminActive)
            }
        }
    }

    return {GetMetaData, SetMetaData, UpdateMetaData, TransformTelephone, Uid, ListenForMetaData, ListenForAdmins, IsAdmin, RecordLastLogin, GetUser, SaveUser}
}

export default useAppUser