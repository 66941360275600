import React, {ReactNode} from "react";
import AuthCheckComponent from "../../data/AuthCheckComponent";
import AdminsListenComponent from "../../data/AdminsListenComponent";

interface Props {
    children: ReactNode;
}

const AuthenticatedPage = ({children} : Props) => {
    return (
        <>
            <AuthCheckComponent />
            <AdminsListenComponent />
            {children}
        </>
    )
}

export default AuthenticatedPage