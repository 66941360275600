import React from 'react';
import LoginFormComponent from "../../content/LoginFormComponent";

interface Props {
}

const LoginPage: React.FC<Props> = () => {
    return (
        <>
            <LoginFormComponent />
        </>
    );
};

export default LoginPage


