import React, {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {InformationCircleIcon, ExclamationCircleIcon, ShieldExclamationIcon, CheckCircleIcon} from '@heroicons/react/24/outline'
import {closeModal, ModalType} from "../../../Services/Redux/features/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import {classNames} from "../../../utils/classNames";
import {RootState} from "../../../Services/Redux/rootState";

interface Props {
}
const ModalComponent: React.FC<Props> = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState(true)
    const cancelButtonRef = useRef(null)

    const onClose = useSelector((state: RootState) => state.modal.onClose)
    const type = useSelector((state: RootState) => state.modal.type)
    const title = useSelector((state: RootState) => state.modal.title)
    const body = useSelector((state: RootState) => state.modal.body)
    const buttonLabel = useSelector((state: RootState) => state.modal.buttonLabel)

    const handleClickClose = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(false)
        dispatch(closeModal())
        onClose()
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 dark:accent-slate-900">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:bg-slate-800">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-slate-800">
                                    <div className="sm:flex sm:items-start">
                                        <div className={classNames("mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10", ((type === ModalType.Error || type === ModalType.Warning) ? "bg-red-100" : "bg-blue-100"))}>
                                            {type === ModalType.Info && <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />}
                                            {type === ModalType.Error && <ShieldExclamationIcon className="h-6 w-6 text-error-600" aria-hidden="true" />}
                                            {type === ModalType.Warning && <ExclamationCircleIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />}
                                            {type === ModalType.Success && <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                {title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-slate-400" dangerouslySetInnerHTML={{__html: body}}>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-slate-700">
                                    <button
                                        type="button"
                                        className={
                                            classNames("inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto", ((type === ModalType.Error || type === ModalType.Warning) ? "bg-red-600 hover:bg-red-500" : "bg-blue-600 hover:bg-blue-500"))
                                        }
                                        onClick={handleClickClose}>
                                        {buttonLabel ? buttonLabel : 'Sluiten'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalComponent;