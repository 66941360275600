import React, {Fragment, useEffect, useRef, useState} from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import useAppUser from "../../../Services/Hooks/User";
import {
    styleButtonSubmit,
    styleFormAstrix, styleFormError,
    styleFormInput,
    styleFormLabel,
    styleHeading3
} from "../../../utils/styles";
import {classNames} from "../../../utils/classNames";

interface Props {
}

interface FormValues {
    firstName: string;
    lastName: string;
    telephone: string;
}

let initialValues: FormValues = {
    firstName: '',
    lastName: '',
    telephone: '',
}

const UserEditComponent: React.FC<Props> = () => {
    const slideOverRef = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const {uid} = useParams()
    const [open, setOpen] = useState(false)
    const location = useLocation();
    const {TransformTelephone} = useAppUser()
    const [isAdmin, setIsAdmin] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const {GetUser,IsAdmin,SaveUser} = useAppUser()

    useEffect(() => {
        if (searchParams.get("open") !== null) {
            setOpen(true)
        }

        if (uid) {
            GetUser(uid)
                .then((user) => {
                    if(user.metadata) {
                        initialValues.firstName = user.metadata.firstName || ""
                        initialValues.lastName = user.metadata.lastName || ""
                        initialValues.telephone = user.metadata.phoneNumber || ""
                    }

                    setIsActive((user.active === undefined ? false : user.active))

                    setIsAdmin(IsAdmin(uid))

                })
                .catch(console.log)
        }

        // eslint-disable-next-line
    }, [setOpen, slideOverRef, searchParams, location]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().min(1).max(30).required('Voornaam is verplicht'),
        lastName: Yup.string().min(1).max(30).required('Achternaam is verplicht'),
        telephone: Yup.string().transform(TransformTelephone).matches(/^0/, "Telefoon nummer moet met een 0 beginnen").matches(/^([0-9]*)$/, "Een telefoon nummer bestaat enkel cijfers.").min(10, 'Telefoon nummer bestaat uit 10 cijfers').max(10, 'Telefoon nummer bestaat uit 10 cijfers').optional(),
    });


    const dismissSlideOVer = () => {
        setOpen(false)
        searchParams.delete("open")
        setSearchParams(searchParams)
    }

    const handleSubmit = async (values: FormValues) => {
        if (uid){
            SaveUser(uid, {
                active: isActive,
                admin: isAdmin,
                metadata: {
                    phoneNumber: values.telephone,
                    firstName: values.firstName,
                    lastName: values.lastName
                }
            })
            dismissSlideOVer()
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={dismissSlideOVer}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="bg-blue-700 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                    Gebruiker wijzig scherm
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={dismissSlideOVer}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-blue-300">
                                                    Wijzig de informatie van een gebruiker. Wees voorzichtig je kunt gebruikers rechten geven en ontnemen.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative flex-1 px-4 py-6 sm:px-6">
                                            {/*{uid}*/}
                                            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                                                {({ errors, touched }) => (
                                                    <Form>
                                                        <div className="space-y-12">
                                                            <div className="border-b border-gray-900/10 pb-12 dark:border-gray-300">
                                                                <h2 className={styleHeading3}>Persoonlijke informatie</h2>
                                                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                                    <div className="sm:col-span-3">
                                                                        <label htmlFor="first-name" className={styleFormLabel}>
                                                                            Voornaam<span className={styleFormAstrix}>*</span>
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <Field
                                                                                type="text"
                                                                                name="firstName"
                                                                                id="firstName"
                                                                                placeholder="Voornaam"
                                                                                autoComplete="given-name"
                                                                                className={styleFormInput}
                                                                            />
                                                                            {errors.firstName && touched.firstName ? (
                                                                                <div className={styleFormError}>{errors.firstName}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className="sm:col-span-3">
                                                                        <label htmlFor="last-name" className={styleFormLabel}>
                                                                            Achternaam<span className={styleFormAstrix}>*</span>
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <Field
                                                                                type="text"
                                                                                name="lastName"
                                                                                id="lastName"
                                                                                placeholder="Achternaam"
                                                                                autoComplete="family-name"
                                                                                className={styleFormInput}
                                                                            />
                                                                            {errors.lastName && touched.lastName ? (
                                                                                <div className={styleFormError}>{errors.lastName}</div>
                                                                            ) : null}

                                                                        </div>
                                                                    </div>

                                                                    <div className="sm:col-span-4">
                                                                        <label htmlFor="telephone" className={styleFormLabel}>
                                                                            Telefoon nummer
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <Field
                                                                                id="telephone"
                                                                                name="telephone"
                                                                                type="tel"
                                                                                autoComplete="tel"
                                                                                placeholder="0612345678"
                                                                                className={styleFormInput}
                                                                            />
                                                                            {errors.telephone && touched.telephone ? (
                                                                                <div className={styleFormError}>{errors.telephone}</div>
                                                                            ) : null}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="border-b border-gray-900/10 pb-12 dark:border-gray-300">
                                                            <h2 className={styleHeading3}>Rechten</h2>
                                                            <div className="grid gap-y-4">
                                                                <div>
                                                                    <Switch.Group as="div" className="flex items-center justify-between">
                                                                      <span className="flex flex-grow flex-col">
                                                                        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                                                                          Actief
                                                                        </Switch.Label>
                                                                        <Switch.Description as="span" className="text-sm text-gray-500">
                                                                          Maak de gebruiker actief
                                                                        </Switch.Description>
                                                                      </span>
                                                                        <Switch
                                                                            checked={isActive}
                                                                            onChange={setIsActive}
                                                                            className={classNames(
                                                                                isActive ? 'bg-blue-600' : 'bg-gray-200',
                                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                                                            )}
                                                                        >
                                                                            <span
                                                                                aria-hidden="true"
                                                                                className={classNames(
                                                                                    isActive ? 'translate-x-5' : 'translate-x-0',
                                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                                )}
                                                                            />
                                                                        </Switch>
                                                                    </Switch.Group>
                                                                </div>
                                                                <div>
                                                                    <Switch.Group as="div" className="flex items-center justify-between">
                                                                      <span className="flex flex-grow flex-col">
                                                                        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                                                                          Admin
                                                                        </Switch.Label>
                                                                        <Switch.Description as="span" className="text-sm text-gray-500">
                                                                          Maak de gebruiker admin
                                                                        </Switch.Description>
                                                                      </span>
                                                                        <Switch
                                                                            checked={isAdmin}
                                                                            onChange={setIsAdmin}
                                                                            className={classNames(
                                                                                isAdmin ? 'bg-blue-600' : 'bg-gray-200',
                                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                                                            )}
                                                                        >
                                                                            <span
                                                                                aria-hidden="true"
                                                                                className={classNames(
                                                                                    isAdmin ? 'translate-x-5' : 'translate-x-0',
                                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                                )}
                                                                            />
                                                                        </Switch>
                                                                    </Switch.Group>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                                            <button
                                                                type="submit"
                                                                className={styleButtonSubmit}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default UserEditComponent