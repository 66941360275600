
export const styleTextParagraph: string = "mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300";

export const styleHeading1: string = "my-10 text-3xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"
export const styleHeading2: string = "my-10 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"
export const styleHeading3: string = "my-10 text-xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"

export const styleFormInput: string = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white dark:ring-white/10 dark:focus:ring-blue-500"
export const styleFormLabel: string = "block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
export const styleButtonSubmit: string = "rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 dark:focus:ring-blue-500"
export const styleButtonCancel: string = "text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300"
export const styleFormAstrix: string = "text-red-500 font-bold ml-1"
export const styleFormError: string = "font-bold text-red-400 text-sm mt-1"