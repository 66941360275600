import React, {useEffect, useState} from "react";
import useAppUser from "../../../Services/Hooks/User";
import {UserCircleIcon} from "@heroicons/react/20/solid";
import {onValue, ref} from "firebase/database";
import {firebaseDatabase} from "../../../Services/Firebase";
import {DB_PATHS} from "../../../Services/Firebase/dal/dbRoutes";
import moment from "moment";
import {NavLink, useNavigate} from "react-router-dom";
import useFormatLinks from "../../../Services/Hooks/useFormatLinks";
import {ROUTE} from "../../../Services/Router/routes";


interface Props {

}

interface IUser {
    active: boolean
    uid: string;
    metadata: IUserMetaData
}

interface IUserMetaData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    lastLogin: number
    lastLoginDate: Date

}


const ListUsersComponent: React.FC<Props> = () => {
    const {IsAdmin} = useAppUser()
    const navigate = useNavigate()

    const [users, setUsers] = useState<IUser[]>([])
    const formatLinks = useFormatLinks()

    useEffect(() => {
        onValue(ref(firebaseDatabase, DB_PATHS.USERS), (snapshot) => {
            let usrs: IUser[] = []
            const data = snapshot.val()
            const keys = Object.keys(data)
            for (let i = 0; i < keys.length; i++) {
                let user = data[keys[i]] as IUser
                user.uid = keys[i]
                user.metadata.lastLoginDate = new Date(user.metadata.lastLogin);
                usrs.push(user)
            }
            setUsers(usrs)
        })
    }, []);

    const openUser = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, uid: string) => {
        event.preventDefault();
        // searchParams.set('open', 'true')
        // setSearchParams(searchParams)

        navigate(formatLinks.Format(ROUTE.USER, {uid: uid}) + "?open")
    }


    return (
        <>
             {/*eslint-disable-next-line*/}
            <ul role="list" className="divide-y divide-gray-100">
                {users.map((person) => (
                    <li key={person.uid} className="flex justify-between gap-x-6 py-5">
                        <div className="flex min-w-0 gap-x-4">
                            <UserCircleIcon className="h-12 w-12 flex-none rounded-full bg-gray-50"/>
                            <div className="min-w-0 flex-auto">
                                <NavLink to={"#"} onClick={(e) => openUser(e, person.uid)} className="text-sm font-semibold leading-6 text-gray-900">{person.metadata.firstName || "Onbekend"} {person.metadata.lastName}
                                </NavLink>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.metadata.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <p className="text-sm leading-6 text-gray-900">{IsAdmin(person.uid) ? "Admin": "Gebruiker"}</p>
                            <p className="mt-1 text-xs leading-5 text-gray-500">
                                {person.metadata.lastLogin && <>Laatst gezien: <time dateTime={moment(person.metadata.lastLogin).fromNow()}>{moment(person.metadata.lastLogin).fromNow()}</time></>}
                            </p>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}
export default ListUsersComponent;
