import React, {useEffect, useState} from "react";
import {classNames} from "../../../utils/classNames";
import {HomeIcon, UsersIcon} from "@heroicons/react/20/solid";
import useAppUser from "../../../Services/Hooks/User";
import {ROUTE} from "../../../Services/Router/routes";

interface Props {

}

interface NavigationItem {
    name: string;
    href: string;
    icon: any;
    current: boolean;
}

const navigation = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
]
const NavigationComponent: React.FC<Props> = () => {

    const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([])

    const {IsAdmin} = useAppUser()

    useEffect(() => {
        let navItems: NavigationItem[] = navigation
        // navItems = navigation
        // console.log("start", navigation)
        // if(IsAdmin()) {
        //     console.log("admin")
        //     navItems.push({ name: 'Gebruikers', href: '#', icon: UsersIcon, current: false })
        // }
        setNavigationItems(navItems)
    }, [navigationItems, IsAdmin]);

    return (
        // eslint-disable-next-line
        <ul role="list" className="-mx-2 space-y-1">
            {navigationItems.map((item) => (
                <li key={item.name}>
                    <a
                        href={item.href}
                        className={classNames(
                            item.current
                                ? 'bg-blue-700 text-white'
                                : 'text-blue-200 hover:text-white hover:bg-blue-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                    >
                        <item.icon
                            className={classNames(
                                item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                        />
                        {item.name}
                    </a>
                </li>
            ))}
            {IsAdmin() && <li>
                <a
                    href={ROUTE.USERS}
                    className={classNames(
                        false
                            ? 'bg-blue-700 text-white'
                            : 'text-blue-200 hover:text-white hover:bg-blue-700',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                    )}
                >
                    <UsersIcon
                        className={classNames(
                            // item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                            'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                    />
                    Gebruikers
                </a>
            </li>}
        </ul>
    )
}
export default NavigationComponent;