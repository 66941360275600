import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";


const firebaseConfig = {
    apiKey: "AIzaSyAzEh0hIsbOnzDvHFcpNURl-kgelb2BehY",
    authDomain: "hulp-aan-zee.firebaseapp.com",
    databaseURL: "https://hulp-aan-zee-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "hulp-aan-zee",
    storageBucket: "hulp-aan-zee.appspot.com",
    messagingSenderId: "704599578396",
    appId: "1:704599578396:web:b2b1fabe0be096af87ccd9",
    measurementId: "G-VVJFELMD98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAppCheck = initializeAppCheck(app, {
    //Todo: make this env variable
    provider: new ReCaptchaV3Provider('6LcAqKMoAAAAANYW8jEQ6RKiZLA1lZXIfsHoLayL'),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

//EA6D585F-8106-4FBD-A9D8-D2A02CF5751E
export const firebaseAnalytics = getAnalytics(app);
export const firebaseAuth = getAuth(app);
export const firebaseDatabase = getDatabase(app);
