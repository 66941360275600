import RootPage from "../../Components/pages/RootPage";
import LoginPage from "../../Components/pages/LoginPage";
import DashboardPage from "../../Components/pages/DashboardPage";
import LogoutPage from "../../Components/pages/LogoutPage";
import PasswordResetPage from "../../Components/pages/PasswordResetPage";
import DefaultPage from "../../Components/pages/DefaultPage";
import OnboardComponent from "../../Components/content/OnboardComponent";
import MenuPage from "../../Components/pages/MenuPage";
import ListUsersComponent from "../../Components/content/ListUsersComponent";
import UserEditComponent from "../../Components/content/UserEditComponent";

export enum ROUTE  {
    ROOT = "/",
    SIGN_IN =  "/inloggen",
    SIGN_OUT =  "/uitloggen",
    DASHBOARD =  "/dashboard",
    ONBOARDING =  "/on-boarding",
    PASSWORD_FORGOTTEN =  "/wachtwoord-vergeten",
    USERS =  "/gebruikers",
    USER =  "/gebruikers/:uid",
}

export const ROUTES = {
    default: [
        {
            route: ROUTE.ROOT,
            children: [<RootPage key={ROUTE.ROOT}/>]
        },
        {
            route: ROUTE.SIGN_IN,
            children: [<LoginPage key={ROUTE.SIGN_IN}/>]
        },
        {
            route: ROUTE.SIGN_OUT,
            children: [<LogoutPage key={ROUTE.SIGN_OUT}/>]
        },
        {
            route: ROUTE.PASSWORD_FORGOTTEN,
            children: [<PasswordResetPage key={ROUTE.SIGN_OUT}/>]
        }
    ],
    authenticated: [
        {
            route: ROUTE.DASHBOARD,
            children: [<MenuPage children={<DashboardPage />} key={ROUTE.DASHBOARD}/>]
        },
        {
            route: ROUTE.USERS,
            children: [<MenuPage children={<><UserEditComponent /><ListUsersComponent /></>} key={ROUTE.USERS}/>]
        },
        {
            route: ROUTE.USER,
            children: [<MenuPage children={<><UserEditComponent /><ListUsersComponent /></>} key={ROUTE.USER}/>]
        },
        {
            route: ROUTE.ONBOARDING,
            children: [<DefaultPage key={ROUTE.ONBOARDING} children={<OnboardComponent />}/>]
        }
    ]
}

