import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import userReducer from "./features/userReducer";
import uiReducer from "./features/uiReducer";
import modalReducer from "./features/modalReducer";

const persistConfig = {
    key: 'root',
    storage,
};

const reducers = combineReducers({
    user: userReducer,
    ui: uiReducer,
    modal: modalReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
})

export const persistor = persistStore(store)