import {NextOrObserver, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User} from "firebase/auth";
import {firebaseAuth} from "./index";

export const signInUser = async (email: string, password: string) => {
    if (!email && !password) return;

    return await signInWithEmailAndPassword(firebaseAuth, email, password)
}

export const resetUserPassword = async (email: string) => {
    if (!email) return;

    return await sendPasswordResetEmail(firebaseAuth, email)
}

export const userStateListener = (callback:NextOrObserver<User>) => {
    return onAuthStateChanged(firebaseAuth, callback)
}

export const SignOutUser = async () => {
    await signOut(firebaseAuth);
}